<!-- 报告小结 -->
<template>
    <div style="padding:20px 24px 40px 24px">
        <div class="globalText">
            <p style="margin-bottom: 14px;">1、目前您的免疫功能储备{{ immuneFunction }}，免疫年龄优于{{ immuneAge
            }}的同年龄段人群，免疫应激性{{ immuneStressRes }}。
            </p>
            <p style="margin-bottom: 14px;">2、您的免疫防御、免疫监视、免疫自稳能力{{ immuneSummary }}</p>
            <p style="margin-bottom: 14px;">
                3、本报告中所使用的指数是一系列敏感指标，也是动态指标；本报告指数仅用于评估此次检测时的状况。随着积极调整及身体状态的改变,指数会显著变化。当您希望积极干预、改善免疫机能时，建议通过饮食调节、规律作息、适当运动以及前沿细胞技术等方式进行。
            </p>
        </div>
        <div class="globalText" style="margin-top: 12px;color: #FF830F;" @click="gotoHealth">
            具体可参考健康管理建议
            <van-icon name="arrow" color="#FF830F" size="14px" />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            immuneAge: 0,
            immuneStressRes: '',
            immuneFunction: '',
            immuneSummary: ""
        }
    },
    created() {
    },
    mounted() {
        let data = JSON.parse(localStorage.getItem('report'))
        this.immuneAge = data.immuneAge
        this.immuneStressRes = data.immuneStressRes
        this.immuneFunction = data.immuneFunction
        this.immuneSummary = data.immuneSummary
    },
    computed: {
    },
    methods: {
        gotoHealth() {
            this.$router.push("/report/immuHealth")
        }
    },
}
</script>
<style lang="scss" scoped></style>
